import React from "react";
import LpDemo from "../../../page-components/LpDemo";
import { lineOfBusiness } from "../../../constants";

export default function DemoPageSideKick() {
  return (
    <LpDemo
      leadType="lead-gen"
      formSubHeader="Adapt and grow your restaurant with an end-to-end solution built by restaurateurs."
      formId="2dc7f5ea-a890-4f8a-977e-6a1dda3db7c4"
      useShortForm
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      lineOfBusiness={lineOfBusiness.HOSPITALITY}
    />
  );
}
